/* ***GLOBAL**** */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./Fonts/Montserrat-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  src: local("Montserrat"), url(./Fonts/Montserrat-Bold.otf) format("opentype");
}

/* .tableMPP table {
  border: 1px solid black;
  border-collapse: collapse;
} */

.tableMPP table {
  border-collapse: collapse;
  width: 100%;
  font-family: Arial, sans-serif;
}

.tableMPP th,
.tableMPP td {
  border: 1px solid black;
  padding: 4px;
  text-align: center;
  white-space: normal; /* Mengatur teks untuk menurun ke bawah jika terlalu panjang */
}

.tableMPP th {
  background-color: #f2f2f2;
  font-size: 14px;
}

.tableMPP tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tableMPP tr:nth-child(odd) {
  background-color: #ffffff;
}

.tableMPP th {
  font-weight: bold;
}

.tableMPP tbody td {
  font-weight: normal;
}

.tableMPP td {
  text-align: center;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 2;
  bottom: 125%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
